import React from 'react';
import { makeStyles } from '@material-ui/core';
import { IoIosColorWand } from 'react-icons/io';
import Mailto from 'react-protected-mailto';
import Layout from '../components/layout';
import SEO from '../components/seo';

const useStyles = makeStyles(() => ({
  underConstructionNotice: {
    margin: 'auto',
    textAlign: 'center',
    color: '#5956ff',
    // textTransform: 'uppercase',
    fontFamily: 'LatoLatinWebThin',
    marginBottom: '10px',
  },
  wandIcon: {
    float: 'right',
    fontSize: '5em',
  },
}));
const IndexPage = () => {
  const classes = useStyles();

  return (
    <Layout>
      <SEO title="Home" />
      {/* <IoIosColorWand className={classes.wandIcon} /> */}
      <div>
        {/* <h3 className={classes.underConstructionNotice}>Website under construction</h3> */}
        <h4 css={{ textAlign: 'center' }}>
          <Mailto email="hello@uwizy.com" headers={{ subject: 'Question from the website' }} />
        </h4>
      </div>
    </Layout>
  );
};

export default IndexPage;
